import React, { useContext } from 'react'
import {
    AppBar,
    Box,
    Button,
    ButtonGroup,
    Divider,
    Menu,
    MenuItem,
    Toolbar,
    Typography
} from '@mui/material'
import { AccountCircle } from '@mui/icons-material'
import NapaLogo from '../../assets/napa_logo.png'
import ApacLogo from '../../assets/apac_logo.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { navigationBarStyles } from './NavigationBarStyles'
import Colors from '../../assets/Colors'
import {AuthContext} from "../../hooks/AuthContext";
import {RequireExactlyOne} from 'type-fest'
import { isApac } from '../../helpers/RegionHelper'

type NavigationMenuItem = {
    menuName: string
    icon?: JSX.Element
    onClick?: (...arg: any) => void
    nav?: string
}

function NavigationBar() {
    const [anchorElAccount, setAnchorElAccount] = React.useState<null | HTMLElement>(null)
    const navigate = useNavigate()
    const location = useLocation()
    const { signOut } = useContext(AuthContext)
    const pages: RequireExactlyOne<NavigationMenuItem, 'nav'>[] = [
        { menuName: 'User Management', nav: '/UserManagement'},
        { menuName: 'Store Management', nav: '/StoreManagement'}
    ]

    function handleAccountMenuOpen (
        event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLLIElement>
    ) {
        setAnchorElAccount(event.currentTarget)
    }

    function handleCloseAccount(): void {
        setAnchorElAccount(null)
    }

    const AccountMenu = () => {
        return (
            <Menu
                id="basic-menu"
                anchorEl={anchorElAccount}
                open={Boolean(anchorElAccount)}
                onClose={handleCloseAccount}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
            >
                <MenuItem onClick={signOut}>Logout</MenuItem>
            </Menu>
        )
    }

    const menus: RequireExactlyOne<NavigationMenuItem, 'onClick'>[] = [
        {
            menuName: 'Settings',
            icon: <AccountCircle sx={navigationBarStyles.navBarMenuIcon} />,
            onClick: handleAccountMenuOpen
        }
    ]

    return (
        <Box sx={navigationBarStyles.navBarContainer}>
            <AppBar component="nav" position="static">
                <Toolbar>
                    <img
                        src={isApac ? ApacLogo : NapaLogo}
                        style={navigationBarStyles.navBarLogo}
                        alt={isApac ? "GPC_logo" : "Napa_logo"}
                        onClick={() => navigate('/')}
                        onKeyDown={() => navigate('/')}
                    />
                    <Typography variant="h6" component="div" sx={navigationBarStyles.navbarTitleStyle}>
                        {isApac ? 'Genuine Delivery' : 'PROTracker'}
                    </Typography>
                    <Box sx={navigationBarStyles.navBarMenuGroup}>
                        <ButtonGroup variant="text">
                            {pages.map((item, idx) => (
                                <React.Fragment key={idx}>
                                    {idx ? (
                                        <Divider
                                            style={{ height: 8, alignSelf: 'center' }}
                                            orientation="vertical"
                                            color={Colors.napaBlue6}
                                        />
                                    ) : null}
                                    <Button
                                        key={idx}
                                        sx={{
                                            ...navigationBarStyles.navbarButtonStyle,
                                            ...((location.pathname === item.nav ||
                                                    (location.pathname === '/' && item.nav === '/UserManagement')) &&
                                                navigationBarStyles.navBarMenuIcon)
                                        }}
                                        onClick={() => navigate(item.nav)}
                                    >
                                        {item.menuName}
                                    </Button>
                                </React.Fragment>
                            ))}
                            {menus.map((menu, idx) => (
                                <React.Fragment key={idx}>
                                    <Divider
                                        style={{ height: 8, alignSelf: 'center' }}
                                        orientation="vertical"
                                        color={Colors.napaBlue6}
                                    />
                                    <Button
                                        sx={{ ...navigationBarStyles.navbarButtonStyle, fontWeight: 'bold' }}
                                        startIcon={menu.icon}
                                        onClick={menu.onClick}
                                        onKeyPress={menu.onClick}
                                    >
                                        {menu.menuName}
                                    </Button>
                                </React.Fragment>
                            ))}
                        </ButtonGroup>
                    </Box>
                    <AccountMenu />
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default NavigationBar
